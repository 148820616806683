.thank{
  padding-top: 2.5rem;
  padding-bottom: 2rem;
  &_img{
    width: 14.125rem;
  }
  &_copy{
    width: 1.5rem;
    height: 1.5rem;
  }
  .rating_wrapper{
    display: flex;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    align-items: center;
    justify-content: center;
    .checkbox{
      align-items: center !important;
    }
    &>div{
      padding: 0 0.5rem;
    }
    &__lg{
      margin-left: -0.75rem;
      margin-right: -0.75rem;
      &>div{
        padding: 0 0.75rem;
      }
    }
  }
  .voice{
    img{
      width: 13.56rem;
    }
  }
}
.all_reviews{
  padding-bottom: 2.8125rem;
  .reviews_count{
    padding: 0.25rem 0.5rem;
    border-radius: $border-radius;
    color: $white;
    font-size: $font-size-base;
    font-weight: 600;
    background-color: $orange;
  }
}
.reviews_form{
  width: 100%;
  padding: 20px;
  background: url('../img/reviews_blue_bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: $border-radius*2;
  overflow: hidden;
  height: 26rem;
  &__wrapper{
    flex: 0 0 21.875rem;
    max-width: 21.875rem;
    .form-group{
      padding-top: 0.625rem;
      padding-bottom: 0;
      &:first-child{
        padding: 0;
      }
    }
  }
  .card{
    &_title{
      padding: 1.5rem 1.5rem 0;
    }
    &_body{
      padding: 1.5rem;
    }
  }
}
.my_reviews {
  .reviews_block {
    padding: 1.875rem;
    &+.reviews_block{
      margin-top: 1.5rem;
    }
    &__img{
      margin-right: 1.5rem;
      padding: 0.875rem;
      background-color: $white;
      border-radius: $border-radius;
      width: 9.375rem;
      //overflow: hidden;
      &-img{
        width: 7.5rem;
        height: 7.31rem;
        img{
          width: 100%;
          min-height: 100%;
          object-fit: cover;
        }
      }
      &-text{
        padding-top: 0.75rem;
        font-size: $font-size-sm;
        font-weight: 400;
      }
    }
    &__edit{
      span{
        &:last-child{display: none;}
      }
      &.editing{
        span{
          &:first-child{display: none;}
          &:last-child{display: block;}
        }
      }
    }
  }
}
.error{
  .product_container__item{
    min-height: 21.5rem;
    max-width: unset;
  }
  &-wrapper{
    margin-bottom: 4.375rem;
  }
  &_img{
    width: 32.25rem;
    height: 20.8125rem;
    margin-bottom: 2.5rem;
  }
}
.progress{
  border: 1px solid $green;
  border-radius: $border-radius*2;
  padding: 1.5rem 2rem;
  background-color: $white;
  &_title{
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1rem;
    color: $green;
    padding-bottom: 1.25rem;
    display: flex;
    align-items: center;
    svg{
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
  }
  &_container{
    position: relative;
  }
  &_path{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 0.5rem;
    width: 100%;
    border-radius: 0.5rem;
    background-color: $green-opacity;
    &__color{
      height: 0.5rem;
      border-radius: 0.5rem;
      background-color: $green;
    }
  }
  &_markers{
    display: flex;
    justify-content: space-between;
    padding: 0 2% 0 20%;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  &_marker{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: $green;
    height: 5.625rem;
    width: max-content;
    &.first{
      color: #000;
      position: absolute;
      left: 0;
      justify-content: flex-end;
    }
    &__sum, &__persent{
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.5rem;
    }
    &__dot{
      width: 0.875rem;
      height: 0.875rem;
      background-color: $green;
      border-radius: 100%;
      &.active{
        width: 1.25rem;
        height: 1.25rem;
        background-color: $white;
        border: 0.1875rem solid $green;
      }
    }
  }
}

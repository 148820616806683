.store_list{
  &__item{
    box-shadow: 0 0.25rem 3.125rem rgba(0, 0, 0, 0.04);
    border-radius: $border-radius*2;
    background-color: $white;
    display: flex;
    overflow: hidden;
    margin-bottom: 1.25rem;
    &:last-child{
      margin-bottom: 0;
    }
    &-img{
      flex: 0 0 12.85rem;
      max-width: 12.85rem;
      img{
        width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }
    &-text{
      padding: 1.25rem;
      &--item{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: $font-size-md;
        font-weight: 400;
        line-height: 1rem;
        margin-bottom: 0.625rem;
        &:last-child{
          margin-bottom: 0;
        }
        svg{
          flex: 0 0 1.5rem;
          max-width: 1.5rem;
          height: 1.5rem;
          margin-right: 0.5rem;
        }
        &>div{
          padding-top: 1px;
        }
      }
    }
  }
}
.main_menu{
  background-color: $blue;
  .cart{
    display: flex;
    align-items: center;
    width: 3.75rem;
    justify-content: flex-end;
    &_count{
      width: 0.75rem;
      height: 0.75rem;
      border-radius:100%;
      background-color: $red;
      color: #fff;
      font-size: 0.625rem;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      right: -0.4rem;
      top: -0.05rem;
    }
    svg{
      margin-top: 0.25rem;
      width: 1.5rem;
      height: 1.5rem;
      fill: #fff;
    }
  }
  &__links{
    display: flex;
    align-items: center;
    width: 5rem;
    border-left: 1px solid #006CD0;
    border-right: 1px solid #006CD0;
    justify-content: center;
    position: relative;
    cursor: pointer;
    &:hover .wrapper_dropdown{
      pointer-events: unset;
      opacity: 1;
      transition: .3s;
      transform: translateY(0);
      top: 100%;
      &__item{
        &:hover{
          background-color: #fdfdfd!important;
        }
        &:nth-child(2n-1){
          background-color: transparent;
        }
        outline:unset;
      }
    }
    &--item{
      display: flex;
      align-items: center;
      justify-content: center;

      svg{
        width: 1.5rem;
        height: 1.5rem;
        fill: #fff;
      }
      .avatar{
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 100%;
        overflow: hidden;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          object-fit: cover;
          width: 100%;
          min-height: 100%;
        }
        span{
          font-size: $font-size-md;
          color: $blue;
        }
      }
      &-text{
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.125rem;
        color: $gray;
      }
    }
  }
  .menu{
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-left: -0.625rem;
    &_item{
      a{
        color: #fff;
        font-weight: 400;
        padding: 0.9rem 0.625rem;
        display: block;
        //transition: 1s;
        //&:hover{
        //  font-weight: 500;
        //}
      }
      .productsMenu{
        &-submenu {
          opacity: 0;
          position: absolute;
          z-index: 500;
          top: 100%;
          left: 0;
          overflow: hidden;
          border-radius: $border-radius;
          background-color: #F9F9FF;
          box-shadow: 0 0.25rem 2rem rgba(120,120,150,0.5);
          transition: 0.3s;
          width: 74.3rem;
          pointer-events: none;
          transform: translateY(30px);
          a{
            &:hover{
              color: $blue-light;
              font-weight: 500;
            }
          }
          .productsMenu-submenu{
            &-w {
              columns: 17rem auto;
              height: auto;
              margin: 1.25rem;
            }
            &-i {
              position: relative;
              background-color: $white;
              margin-right: 0.9375rem;
              border-radius: $border-radius*2;
              padding: 1.25rem;
              width: 17.2rem;
            }
            &-a {
              line-height: 3rem;
              color: $blue;
              display: flex;
              align-items: center;
              margin-bottom: 0.625rem;
              padding: 0;
            }
            &-image {
              flex: 0 0 2rem;
              max-width: 2rem;
              height: 2rem;
              margin-right: 0.625rem;
              img {
                object-fit: contain;
                width: 100%;
                height: 100%;
              }
            }
            &-t {
              color: $black;
              font-size: $font-size-md;
              font-weight: 500;
              line-height: 1.5;
            }
          }
          &-i {
            page-break-inside: avoid;
            break-inside: avoid;
            vertical-align: top;
            margin-bottom: 1.25rem;
          }
          &-a {
            position: relative;
            display: block;
            border: 0;
          }
          &-t {
            margin-bottom: 0;
            font-size: 1rem;
            font-weight: 700;
            display: block;
          }
        }
        &-list {
          font-size: 0.75rem;
          &-i {
            padding: 0.625rem 0.75rem;
            border-radius: $border-radius;
            background-color: $gray-100;
            a{
              color: $black;
              font-size: $font-size-sm;
              padding: 0 !important;
            }
            &:nth-child(2n){
              background-color: $white;
            }
          }
        }
      }
      &:hover .productsMenu-submenu {
        pointer-events: unset;
        opacity: 1;
        transform: translateY(0px);
        transition: .3s;
      }
    }
  }
  .stores{
    padding: 0.75rem 0.75rem;
    width: 46.375rem;
    display: flex;
    flex-wrap: wrap;
    &_item{
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0.5rem;
      &__wrapper{
        background-color: #fff;
        border-radius: $border-radius*2;
        overflow: hidden;
        display: flex;
      }
      &__img{
        flex: 0 0 8.75rem;
        max-width: 8.75rem;
        height: 8.875rem;
        img{
          min-height: 100%;
          object-fit: cover;
        }
      }
      &__data{
        flex-grow: 1;
        padding: 1.25rem 1.5rem;
        .title{
          font-size: $font-size-base;
          font-weight: 500;
          margin-bottom: 0.625rem;
        }
        .item{
          padding-left: 1.125rem;
          position: relative;
          font-size: $font-size-sm;
        }
        svg{
          position: absolute;
          left: 0;
          top: 0;
          width: 0.875rem;
          height: 0.875rem;
        }
      }
    }
  }
}
header{
  position: relative;
  z-index: 1;
  .header{
    &_top{
      background-color: #fff;
      &__wrapper{
        height: 4.375rem;
      }
      &__menu{
        padding: 0.75rem 0;
        display: flex;
        align-items: center;
        .site-menu{
          display: flex;
          &__item{
            padding: 0 0.5rem;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1.125rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            a{
              display: flex;
              align-items: center;
            }

          }
        }
        &--lang{
          position: relative;
        }
      }
      &__logo{
        padding-right: 0.75rem;
        display: flex;
        align-items: center;
        *{
          height: 2.25rem;
        }
      }
      &__search{
        padding: 0 2rem;
      }
    }
  }
}
.catalog{
  display: flex;
  margin: 0 -0.75rem;
  &_sidebar{
    padding: 0 0.75rem;
    flex: 0 0 17.625rem;
    max-width: 17.625rem;
  }
  &_content{
    padding: 0 0.75rem;
    flex: 0 0 calc(100% - 17.625rem);
    max-width: calc(100% - 17.625rem);
  }
  .articles_slider__item{
    margin-bottom: 1.25rem;
    display: flex;
    flex-direction: column;
    .article{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      &_title{
        justify-content: center;
        display: flex;
        align-items: center;
        padding-left: 2rem;
        padding-right: 2rem;
        flex-grow: 1;
      }
      &_img{
        height: 13.625rem;
        img{
          min-height: 100%;
        }
      }
    }
  }
}
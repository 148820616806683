.before_footer{
  padding-top: 4.375rem;
  display: flex;
  &__item{
    flex: 0 0 25%;
    max-width: 25%;
    background-color: $white;
    padding: 1.25rem 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #dddddd;
    &:last-child{
      border: none;
    }
    &-img{
      padding-right: 1.125rem;
      svg{
        width: 3.3125rem;
        height: 3.3125rem;
      }
    }
    &-desc{
      .title{
        font-size: 1rem;
        font-weight: 500;
        padding-bottom: 0.25rem;
      }
      .text{
        font-size: 0.75rem;
        font-weight: 400;
      }
    }
  }
}
.before_footer-lading{
  .before_footer{
    padding-top: 0;
    &__item {
      background-color: transparent;
      border: none;
    }
  }
}

.brands{
  &_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem 1rem 1.2rem;
    background-color: #fff;
  }
  &_item{
    width: 10.3125rem;
    height: 6.25rem;
    padding: 0 1.093rem;
    &:last-child{
      width: unset;
      height: unset;
      padding-right: 0;
      flex-grow: 1;
    }
  }
}
.filter_product{
  display: flex;
  ._filter{
    width: 18.75rem;
    .filter{
      .btn{
        font-size: $font-size-md;
        color: $white;
        width: 3.875rem;
        height: 2rem;
        border-radius: 0.1875rem;
        background-color: $blue;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.25rem;
      }
      &-section{
        margin-bottom: 2.1875rem;
      }
      &-color-list{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -0.3125rem;
        .filter-color-i{
          display: block;
          padding: 0 0.3125rem 0.625rem;
          &.__active{
            position: relative;
            &::before{
              content: '';
              position: absolute;
              width: 2.1875rem;
              height: 2.1875rem;
              border-radius: 0.3rem;
              border: 0.15rem solid $blue;
              margin-top: -0.1rem;
              margin-left: -0.1rem;
            }
          }
          .filter-color-b{
            display: block;
            width: 2.1875rem;
            height: 2.1875rem;
            border-radius: 0.1875rem;
            background-size: cover;
          }
        }
      }
      &-check{
        display: block;
        margin-bottom: 0.9375rem;
        .label{
          display: flex;
          align-items: center;
        }
        .checkbox{
          flex: 0 0 1.25rem;
          height: 1.25rem;
          border-radius: 0.1875rem;
          border: 1px solid $gray-400;
          margin-right: 0.625rem;
        }
        &.__active{
          .checkbox{
            border: 1px solid transparent;
            background-color: $blue;
            position: relative;
            &::before{
              content: "";
              position: absolute;
              left: 50%;
              top: 42%;
              -webkit-transform: translate(-50%,-50%) rotateZ(-45deg);
              transform: translate(-50%,-50%) rotateZ(-45deg);
              width: 0.65rem;
              height: 0.325rem;
              border-left: 2px solid #fff;
              border-bottom: 2px solid #fff;

            }
          }
        }
      }
      &-price{
        .label-none{
          display: none;
        }
        &-inputs{
          display: flex;
          align-items: center;
          margin-bottom: 1.25rem;
        }
        &-field{
          width: 7.4875rem;
          height: 2rem;
          border-radius: 0.1875rem;
          border: 1px solid $gray-400;
          padding: 0.5rem 0.625rem;
          color: $black;
          font-size: $font-size-md;
          margin-right: 0.3125rem;
        }
        &-tracker{
          position: relative;
          &.ui-slider{
            width: 100%;
            height: 0.125rem;
            background-color: $blue-lighten;
          }//весь путь
          .ui-slider-range{
            background-color: $blue;
            position: absolute;
            height: 0.125rem;
          }//обраний
          .ui-slider-handle{
            border-radius: 100%;
            background: $blue;
            box-shadow: none;
            position: absolute;
            box-sizing: border-box;
            width: 0.8125rem;
            height: 0.8125rem;
            top: 50%;
            transform: translateY(-50%);
          } //маркери
        }
      }
    }
  }
  ._result{
    padding-left: 3.5rem;
    flex-grow: 1;
  }
  .filter{
    &-current{
      &-group{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &+.filter-current-group{
        }
      }
      &-heading{
        padding-right: 0.5rem;
        margin-bottom: 0.3rem;
        i{display: none;}
      }
      &-i{
        padding: 0.125rem 0.375rem;
        border-radius: 0.25rem;
        background-color: $blue;
        color: $white;
        display: flex;
        align-items: center;
        width: max-content;
        margin-right: 0.625rem;
        margin-bottom: 0.5rem;
      }
    }
    &-clear{
      height: 2rem;
      padding: 0 1.25rem;
      display: flex;
      align-items: center;
      background-color: $gray-400;
      border-radius: 0.25rem;
      width: max-content;
      margin-top: 0.75rem;
    }
    &-section{
      &-h{
        font-weight: 500;
        margin-bottom: 1.25rem;
      }
    }
    &-lv1{
      margin-top: 0;
    }
  }
  .article{
    &-title{
      font-weight: 500;
      margin-bottom: 1.25rem;
    }
    &-list{
      font-size: $font-size-md;
      color: $blue;
      margin-bottom: 1rem;
      display: block;
    }
  }
}
.result{
  &_product{
    &__wrapper{
      margin: 0 -0.625rem;
      display: flex;
      flex-wrap: wrap;
    }
    width: 33.3%;
    max-width: 33.3% !important;
    padding: 0 0.625rem;
    margin-bottom: 1.25rem;
    .product_container__item{
      max-width: unset;
      .available{
        font-size: $font-size-sm;
        align-items: center;
      }
      &-img{
        height: 12.2rem;
      }
      &-info{
        .desc{
          font-size: $font-size-md;
        }
      }
    }
  }
  &_catalog{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.375rem;
    align-items: stretch;
    .catalog-grid__item{
      flex: 0 0 16%;
      max-width: 16%;
      padding: 0 0.375rem;
      margin-bottom: 0.75rem;
      display: flex;
      .catalogCard{
        background-color: #fff;
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.04);
        border-radius: $border-radius;
        padding: 0.625rem;
        &-a{
          display: flex;
          flex-direction: column;
        }
        &-image{
          margin-bottom: 0.5rem;
          img,picture{
            width: 100%;
            height: 7.5rem;
            object-fit: contain;
          }
        }
        &-title{
          font-size: $font-size-md;
          font-weight: 400;
          text-align: center;
        }
      }
    }
  }
  .result_sort{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.375rem 0 1.625rem;
    &__items{

    }
    &__block{
      display: flex;
      align-items: center;
      margin: 0 -0.3125rem;
      &-item{
        padding: 0 0.3125rem;
        .white-box{
          padding: 0 1rem;
          height: 2.625rem;
          display: flex;
          align-items: center;
          font-size: $font-size-md;
          color: #000;
          font-weight: 400;
          background-color: #fff;
          border-radius: $border-radius;
          img{
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.625rem;
          }
        }
      }
    }
  }
  .gray_block{
    padding: 1rem 1.25rem;
    .reviews_block__img{
      margin-right: 1.5rem;
      background-color: #fff;
      border-radius: $border-radius;
      max-width: 9.375rem;
      &-text{
        padding: 0.3125rem 0.625rem 0.75rem 0.875rem;
        font-size: $font-size-sm;
      }
      &-img{
        width: 9.4rem;
        height: 7.5rem;
        img{
          object-fit: contain;
          max-height: 100%;
        }
      }
    }
  }
  .bottom-link{
    padding: 4.375rem 0;
    margin: 0 -0.5rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    a{
      margin: 0 0.5rem;
      color: $black;
      text-decoration: none;
      border-bottom: 1px dotted $black;
      margin-bottom: 0.75rem;
    }
  }
}
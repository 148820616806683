.checkout{
  background-color: $gray-500;
  padding-top: 2.2rem;
  .basket_item{
    box-shadow: none;
    border: 1px solid #DDDDDD;
  }
  .change-order-btn{
    top: 50%;
    right: 1.5rem;
    display: none;
    transform: translateY(-50%);
  }
  .card.error{
    border: 1px solid $red !important;
    .wrapper_field{
      span{
        color: $red;
      }
    }
  }
  .wrapper_field{
    display: none;
    margin: 0 -0.625rem;
    span{
      padding: 0 0.625rem;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        width: 0.25rem;
        height: 0.25rem;
        border-radius: 100%;
        background-color: #000;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
      &:first-child::before{
        display: none;
      }
    }
  }
  .not-active{
    box-shadow: none;
    .btn-primary{
      display: none;
    }
    .btn-primary-outline{
      display: flex !important;
    }
    .get_in, .wrapper_data{
      display: none;
    }
    .wrapper_field{
      display: flex;
    }
    &.card{
      background-color: #F0F0FE !important;
      box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.04);
      .card{
        &_body{
          display: flex;
          padding: 1.25rem 17rem 0.75rem 1.5rem;
          flex-wrap: wrap;
        }
        &_title {
          padding: 2rem 2rem 0 2rem;
          span{
            background-color: $white;
          }
        }
      }
    }
    .widget{
      height: 0;
      opacity: 0;
      overflow: hidden;
      pointer-events: none;
    }
    .basket_item{
      width: unset;
      height: unset;
      padding-right: 0;
      margin-bottom: 0.75rem;
      margin-right: 0.75rem;
      border: none;
      overflow: hidden;
      &-img{
        flex: 0 0 4.875rem;
        max-width: 4.875rem;
        height: 5.3125rem;
      }
      img{
        object-fit: cover;
        transform: translate(-50%, -50%);
        margin: 50% 0 0 50%;
      }
      &-text,&-counter,&-price,svg{
        display: none;
      }
    }
  }
  .address_fields{
    margin: 0 -0.375rem;
    &>div{
      padding: 0 0.375rem;
      flex: 0 0 16.66%;
      max-width: 16.66%;
      &:first-child{
        flex: 0 0 50%;
        max-width: 50%;
      }
      input{
        padding: 0.5rem;
      }
    }
  }
  .description_pay-type{
    width: 29.5rem;
    padding: 1rem 1.25rem;
    border-radius: 0.625rem;
    background-color: $gray-100;
  }

  .order-details-i{
    //right: calc(100% + 3rem);
    //position: absolute;
    //background-color: #fff;
    //border-radius: 0.625rem;
    //z-index: 10;
    //width: 100%;
    //padding: 1rem;
    //box-shadow: 0 0 3.125rem rgba(0,0,0,0.1);
    b{
      font-weight: 300;
    }
    .order-discount-tooltip{
      overflow: auto;
      height: 10rem;
    }
    .order-details-h{
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 1rem 0 1rem 1rem;
        border-color: transparent transparent transparent $white;
        right: -1.8rem;
        top: -0.75rem;
      }
    }
  }
}
.basket{
  &_modal{
    position: fixed;
    right: -100%;
    top:7.4rem;
    bottom: 0;
    width: 44.5rem;
    background-color: $gray-100;
    overflow: auto;
    &.basket_show{
      right: 0;
    }
    &__wrapper{
      padding: 1.75rem 1.875rem 1.875rem;
    }
    &__header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1.875rem;
      &-title{
        font-size: 1.625rem;
        line-height: 1.875rem;
      }
      &-close{
        cursor: pointer;
        svg{
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
    .empty{
      display: flex;
      flex-direction: column;
      align-items: center;
      &>svg{
        width: 14.375rem;
        height: 13.1875rem;
      }

    }
  }
  &_item{
    width: 100%;
    height: 7.2rem;
    border-radius: 0.625rem;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.04);
    background-color: #fff;
    margin-bottom: 0.625rem;
    padding-right: 2.8rem;
    position: relative;
    &.basket_item__sm{
      height: 4.375rem;
      box-shadow: none;
      border: 1px solid #DDDDDD;
      border-radius: 0.5rem;
      padding-right: 1.25rem;
      .basket_item{
        &-text{
          padding: 0;
          flex: unset;
          max-width: unset;
        }
        &-img{
          flex: 0 0 2.625rem;
          max-width: 2.625rem;
          margin: 0.5rem 1rem 0.5rem 0;
        }
      }
    }
    svg{
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }
    &-img{
      flex: 0 0 6.56rem;
      max-width: 6.56rem;
      padding: 0.5rem;
      img{
        width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    &-text{
      flex: 0 0 20rem;
      max-width: 20rem;
      display: flex;
      align-items: center;
      padding: 0 2.8rem;
    }
    &-counter{
      display: flex;
      align-items: center;
      padding-right: 2.8rem;
      width: max-content;
    }
    &-price{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
    }
    &.is-enlarged{
      height: unset;
      overflow: hidden;
    }
  }
}

// Variables
$basefont:'Roboto', sans-serif;

$border-radius: 0.3125rem;//5px

$white:          #fff !default;
$black:          #000 !default;
$black-light:    #292929 !default;
$gray:           #333333 !default;
$gray-lighten:   #464646 !default;
$gray-light:     #767676 !default;
$gray-lighten1:  #AEAEAE !default;
$blue:           #0F8CFF !default;
$blue-light:     #4faaff !default;
$blue-lighten: rgba(79, 170, 255, 0.6) !default;
$red:            #FF0000 !default;
$red_light:      #FF424D !default;
$red_opacity:    #FFF1F1 !default;
$yellow:         #FFD200 !default;
$orange:         #FF880E !default;
$dark-orange:    #FC7F38 !default;
$green:          #009217 !default;
$green-light:    #38BC5A !default;
$green-opacity:    #EDFFEC !default;
$violet:         #7360F2 !default;

$gray-100: #F7F7FC;
$gray-200: #B7B7BA;
$gray-300: #9F9F9F;
$gray-400: #DDDDDD;
$gray-500: #F9F9FF;






$font-size-base:           1rem !default;//16px
$font-size-xl:             1.25rem !default;//20px
$font-size-lg:             1.125rem !default;//18px
$font-size-md:             0.875rem !default;//14px
$font-size-sm:             0.75rem !default;//12px
$font-size-xs:             0.625rem !default;//10px


$h1:  2rem; // 32px
$h2:  1.75rem; // 28px
$h3:  1.5rem; // 24px
$h4:  1.375rem; // 22px

$title_26:             1.625rem !default;//26px
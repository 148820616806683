//@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../plugins/slick/css/slick.css";
@import "../plugins/slick/css/slick-theme.css";
@import "../plugins/toastr/toastr.css";
//@import "../plugins/select2/css/select2.min.css";


@import "variables";
@import "typography_site";
@import "main_menu";
@import "breadcrumbs";

//@import "main_gallery";

@import "product";
@import "products_list";
@import "filter_product";
@import "checkout";

@import "basket";
@import "search";
@import "thank";
@import "landing";
@import "error";
@import "contact";
@import "catalogue/catalog";
@import "catalogue/article_page";
@import "catalogue/store_list";
@import "catalogue/reviews";
@import "personal_account/account";
@import "personal_account/personal";
@import "personal_account/my_orders";
@import "personal_account/my_rewievs";


@import "progress";
@import "before_footer";

@import "header";
@import "footer";



//@import "landing";
//@import "pages/business";
//@import "pages/news";


.search {
  &_result{
    padding-bottom: 3.125rem;
    a {
      border-bottom: 0.0875rem dashed #000000;
    }
  }
  &_noresults {
    &-img {
      margin-right: 4.75rem;
    }
    &-info {
      li {
        padding-left: 1.25rem;
        margin-bottom: 0.75rem;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background: #0f8cff;
          left: 0;
          top: 0.375rem;
        }
        &:last-child {
          margin-bottom: 2rem;
        }
      }
    }
  }
  &_noresult{
    &-possible {
      max-width: 43.375rem;
      &-title {
        font-size: 0.8125rem !important;
        margin: 0 0 0.625rem 0;
        color: #707070;
      }
      a {
        border-bottom: 0.0875rem dashed #000000;
      }
    }
    &-btn {
      margin: 1.875rem -0.5rem 0 -0.5rem;
      max-width: 36.625rem;

      &--item {
        padding: 0 0.5rem;
      }
    }
  }
  .recommendation-title {
    margin: 4.375rem 0 1.875rem 0;
  }
  &-tab{
    &-nav {
      padding-bottom: 1.875rem;
      ul {
        li {
          display: inline-block;
          &.ui-tabs-active.ui-state-active{
            a {
              color: #ffffff;
              background-color: #303030;
            }
          }
          &.ui-state-hover{
            a{
              background-color: $gray-400;
              color: #000000
            }
          }
          a {
            padding: 0.625rem 1.75rem;
            margin-right: 1rem;
            background-color: #ffffff;
            border-radius: $border-radius;
            transition: 0.3s;
          }
        }
      }
    }
    &-content {
      .tab-element {
        display: none;
      }
      .active {
        display: block;
      }
      .widget {
        padding: 0;
        .product_container {
          margin-left: 0;
        }
      }
    }
  }
  .product_container__item {
    max-width: unset;
    &-img {
      height: 12rem;
    }
  }
}

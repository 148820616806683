.account{
  &_sidebar{
    .arrow_status{
      width: 1rem;
      height: 1rem;
      right: 1rem;
      top: 1.4rem;
    }
    &__banner{
      background-image: url(../img/personal_acc/square_orange-bg.jpg);
      background-size: cover;
      padding: 2rem 1.875rem;
      box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.04);
      border-radius: $border-radius*2;
      color: $white;
    }
  }
  &_data{
    &.empty{
      //.action_slider__wrapper{
      //  padding: 0 !important;
      //}
      .card_body{
        padding-top: 1.25rem;
      }
      .empty_box{
        width: 13.8125rem;
        height: 13.8125rem;
        margin-bottom: 1rem;
      }
      .empty_title{
        font-size: 1.625rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }
      .empty_subtitle{
        font-size: $font-size-base;
        margin-bottom: 1rem;
        font-weight: 400;
      }
    }
  }
}

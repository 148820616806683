.footer {
  padding: 3.125rem 1rem 4.5rem;
  *{
    font-weight: 400;
    color: #fff !important;
    font-size: 0.75rem;
  }
  li{
    line-height: 1rem;
    margin-bottom: 0.4375rem;
  }
  background-color: #252525;
  &-copyright{
    margin-bottom: 1rem;
  }
  &_wrapper{
    display: flex;
    justify-content: space-between;
  }
  &_left{
    flex:0 0 calc(34% - 7px);
    max-width: calc(34% - 7px);
    font-size: 0.75rem;

    img{
      margin-bottom: 2.25rem;
      max-width: 14.75rem;
    }
  }
  &_right{
    flex-grow: 1;
    display: flex;
    &__item{
      flex: 0 0 25rem;
      max-width: 25%;

      .text-sm{
        margin-bottom: 0.375rem;
      }
      .footer-social{
        p{
          margin-bottom: 0.5rem;
        }
        noindex{
          margin-right: 0.7rem;
        }
        svg{
          width: 1.5rem;
          height: 1.5rem;
          fill: $white;
        }
      }
      .footer-tels{
        position: relative;
        ul{
          margin: 0;
          li{
            padding: 0.27rem 0;
            margin-bottom: 0;
            position: relative;
            font-size: 0.875rem;
          }
        }
        svg{
          width: 1rem;
          height: 1rem;
          fill: $white;
          position: absolute;
          left: -1.5rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .footer-email{
        margin-top: 1rem;
        margin-bottom: 1.75rem;
      }
      .visaMC{
        width: 5.5rem;
        margin: 0 auto;
      }
    }
  }
}
.article_page{
  &__content{
    padding: 0 0.75rem;
    flex: 0 0 calc(100% - 35.25rem);
    max-width: calc(100% - 35.25rem);
    p{
      font-size: 1rem;
    }
  }
}
.prod_theme{
  .product_container__item{
    max-width: unset;
  }
}

.product{
  &_title{}
  &_header{
    margin-bottom: 2rem;
  }
  &_article{
    height: 2rem;
    padding: 0 0.375rem;
    background-color: $red_light;
    color: #fff;
    display: flex;
    align-items: center;
    width: max-content;
    font-size: $font-size-md;
    margin-right: 1rem;
    border-radius: 0.1875rem;
  }
  .reviews{
    display: flex;
    align-items: center;
  }
  &_body{
    margin: 0 -0.75rem;
    display: flex;
    flex-wrap: wrap;
    .gray{
      color: $gray-300;
    }
    &__left{
      padding: 0 0.625rem;
      flex: 0 0 61.86%;
      max-width: 61.86%;
      .review_count{
        height: 1.625rem;
        padding: 0 0.5rem;
        color: #fff;
        line-height: 600;
        font-size: $font-size-base;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $orange;
        border-radius: $border-radius;
      }
    }
    &__right{
      padding: 0 0.625rem;
      flex: 0 0 38.14%;
      max-width: 38.14%;
      .size{
        span{
          color: #000;
          text-decoration: underline;
          font-weight: 500;
          cursor: pointer;
        }
        svg{
          fill: $blue;
          width: 1.0625rem;
          height: 1.0625rem;
        }
        &_blocks{
          display: flex !important;
          gap: 0.3125rem;
          padding-bottom: 1.875rem;
          margin-bottom: 1.5rem;
          border-bottom: 1px solid #EDEDED;
          &>div, a{
            cursor: pointer;
            background-color: #F8F8FD;
            color: #000;
            border-radius: $border-radius;
            padding: 0.625rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 2.5rem;
            min-width: 4.06rem;
            max-height: 8rem;
            position: relative;
            border: 1px solid transparent;
            &.sm_3{
              height: 2.8rem;
            }
            svg{
              opacity: 0;
              position: absolute;
              width: 4rem;
              height: 4rem;
              z-index: 1;
            }
            &.sending{
              opacity: 0.5;
              cursor: not-allowed;
              pointer-events: none;
              span{
                opacity: 0.5;
              }
              svg{
                opacity: 1;
              }
            }
            &.modification__button--stockout{
              opacity: 0.7;
              border: 1px solid transparent;
            }
            &.modification__button--active{
              border: 1px solid $blue;
            }
            span{
              font-size: 1.25rem;
              font-weight: 400;
              line-height: 1.5rem;
              +span{
                font-size: 0.75rem;
                line-height: 1.25rem;
              }
            }
          }
        }
      }

    }
  }
  &_container{
    margin: 0 2.625rem;
    &.small{
      margin: 0 2.4rem;
      .product_container__wrapper{
        padding: 0 0.1875rem;
      }
      .product_container__item{
        border: 1px solid #ddd;
        &-img{
          width: 100%;
          height: 6.93rem;
        }
        &-info{
          padding: 0.625rem 0.75rem 0.25rem 0.75rem;
          .desc{
            margin-bottom: 0.5rem;
          }
        }
      }
      .product_container__footer{
        .sum{
          font-weight: 700;
          display: flex;
          align-items: flex-end;
          position: relative;
         .discount{
           padding: 0.125rem;
           color: #fff;
           background-color: $dark-orange;
           font-size: 0.5rem;
           line-height: 0.5625rem;
           font-weight: 500;
           left: 0;
           top: -0.125rem;
           position: absolute;
         }
        }
        .cart{
          width: 1.875rem;
          height: 1.75rem;
          svg{
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
    &__wrapper{
      padding: 0 0.625rem;
      max-width: 19.375rem;
    }
    &__item{
      background-color: #fff;
      border-radius: 0.625rem;
      overflow: hidden;
      width: 100%;
      max-width: 13.875rem;
      &-img{
        width: 100%;
        height: 9.625rem;
        img{
          width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
      &-info{
        padding: 0 1.25rem 1.625rem 1.25rem;
        .desc{
          font-weight: 400;
          font-size: $font-size-sm;
          line-height: 0.9rem;
          margin-bottom: 1.125rem;
        }
      }
    }
    &__footer{
      display: flex;
      justify-content: space-between;
      .sum{
        font-size: $font-size-md;
        font-weight: 500;
        line-height: 1rem;
      }
      .cart{
        background-color: $blue;
        width: 2.125rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius;
        svg{
          fill: $white;
          width: 1.125rem;
          height: 1.125rem;
        }
      }
    }
  }
  &_size__grid{
    &-title{
      display: flex;
      height: 2.125rem;
      padding: 0 1.125rem;
    }
    &-line{
      display: flex;
      height: 2.125rem;
      padding: 0 1.125rem;
      &:nth-child(2n){
        background-color: $gray-100;
        border-radius: $border-radius;
      }
    }
    .item{
      flex: 0 0 50%;
      max-width: 50%;
      display: flex;
      align-items: center;
      font-size: $font-size-md;
      &:first-child{
        font-weight: 600;
      }
    }
  }
  .modification__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 1rem 0;
  }
  .modification__item {
    position: relative;
    margin: 0;
    display: block;
    border: 0;
    padding: 1px !important;
    height: 3rem;
  }
  .modification__item--active .modification__color, .modification__item--active .modification__preview {
    cursor: default;
  }

  .modification__color {
    display: block;
    position: absolute !important;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: unset !important;
    height: unset !important;
    box-shadow: unset !important;
  }
  .modification__color, .modification__preview {
    border-radius: $border-radius;
    position: relative;
    background-size: cover !important;
  }
  .modification__item--active .modification__color:before, .modification__item--active .modification__preview:before {
    content: '';
    position: absolute;
    border: 1px solid #0466b1;
    border-radius: $border-radius;
    width: calc(100% - 2px);
    top: 0;
    bottom: 0;
  }
}